<template>
  <section class="create-invoices">
    <FlashMessage
      :status="submitStatus"
      :message="submitMessage"
      @onCloseFlash="onCloseFlash"
    />
    <button
      v-if="isValid"
      class="btn btn-primary float-right mb-2"
      @click="submitData"
    >
      {{ $t('invoice.submit_form') }}
    </button>
    <button
      v-else
      class="btn btn-primary float-right mb-2"
      @click="checkData"
    >
      {{ $t('invoice.check_form') }}
    </button>
    <InputDate
      v-model="month"
      input-id="month-invoice"
      :label-text="$t('invoice.month')"
      :language="$i18n.locale"
      :date-format="'MM/yyyy'"
      :minimum-view="'month'"
      :maximum-view="'year'"
      :value-input="month"
      col-label="mr-3"
      col-input="form-inline text-center"
    />
    <div
      v-show="loaded"
      class="hot-table-invoices"
    >
      <hot-table
        ref="invoices"
        :settings="hotSettings"
      />
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import { isEmpty, forEach, some } from 'lodash';
import { HotTable } from '@handsontable/vue';
import InputDate from '../../components/inputDate';
import FlashMessage from '../../components/flash-message';
import { HyperFormula } from 'hyperformula';

export default {
  components: {
    InputDate,
    HotTable,
    FlashMessage
  },
  data() {
    const self = this;
    return {
      hotSettings: {
        height: 'auto',
        stretchH: 'all',
        className: 'htCenter htMiddle',
        rowHeights: 30,
        rowHeaders: true,
        manualColumnResize: true,
        nestedHeaders: [
          ['', 'Phòng', 'Đặt cọc', 'Tiền phòng', 'Ngày sử dụng', { label: 'Điện', colspan: 4 },
           { label: 'Nước', colspan: 4 }, { label: 'Internet', colspan: 2 }, { label: 'Dịch vụ', colspan: 2 },
           { label: 'Phí gửi xe', colspan: 3 }, 'Tổng tiền', 'Giảm trừ', 'Tăng thêm', 'Chủ phòng', 'Ghi chú' ],
          ['', '', '', '', '', 'Số đầu', 'Số cuối', 'Sử dụng', 'Thành tiền',	'Số đầu', 'Số cuối', 'Sử dụng',
           'Thành tiền', 'Đơn giá', 'Thành tiền', 'Đơn giá', 'Thành tiền', 'Đơn giá', 'Số lượng', 'Thành tiền',
           '', '' ],
          ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y']
        ],
        columns: [
          { data: 'check', editor: false, type: 'checkbox' },
          { data: 'key', readOnly: true },
          { data: 'deposited_money', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'room_price', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'day_used_per_month', type: 'numeric' },
          { data: 'ele_begin_number', type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'ele_end_number', type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'ele_qty', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'ele_total', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'wat_begin_number', type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'wat_end_number', type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'wat_qty', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'wat_total', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'internet_unit', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'internet_total', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0.0' } },
          { data: 'service_unit', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'service_total', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0.0' } },
          { data: 'parking_fee_unit', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'parking_fee_qty', type: 'numeric' },
          { data: 'parking_fee_total', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0.0' } },
          { data: 'total', readOnly: true, type: 'numeric', numericFormat: { pattern: '0,0.0' } },
          { data: 'reduce', type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'added', type: 'numeric', numericFormat: { pattern: '0,0' } },
          { data: 'holder_name', readOnly: true },
          { data: 'note' },
          { data: 'contract_id', readOnly: true },
          { data: 'room_id', readOnly: true }
        ],
        hiddenColumns:{
          columns: [25, 26]
        },
        formulas: {
          engine: HyperFormula
        },
        afterValidate(isValid, value, row, prop, source) {
          if (source !== 'edit') return;

          const col = this.propToCol(prop);
          switch(col) {
            case 4:
              return value < self.totalDayInMonth;
            case 5:
            case 9:
              return value < this.getDataAtCell(row, col + 1);
            case 6:
            case 10:
              return value > this.getDataAtCell(row, col - 1);
            default:
              break;
          }
        },
        // cells(row, col) {
        //   const cellProperties = {}

        //   if (self.hotRef.getData()[row][2] === '') {
        //     cellProperties.readOnly = true
        //   }

        //   return cellProperties
        // },
        licenseKey: 'non-commercial-and-evaluation'
      },
      month: new Date(),
      isValid: false,
      hotRef: '',
      loaded: false,
      submitStatus: '',
      submitMessage: ''
    };
  },
  computed: {
    totalDayInMonth() {
      return new Date(new Date(this.month).getFullYear(), new Date(this.month).getMonth() + 1, 0).getDate();
    }
  },
  watch: {
    async month(val) {
      this.submitStatus = true;
      this.submitMessage = this.$t('invoice.loadding');

      const month = `${val.getMonth() + 1}/${val.getFullYear()}`;
      const response = await axios.get('/batch_create/invoices/new.json', {
        params: { invoices: { month } },
        paramsSerializer: function(params) {
          return jQuery.param(params);
        }
      });
      const { data, status } = response;
      if (status == 200) {
        this.updateDataHotSetting(data.build);
      }
    }
  },
  async created() {
    this.submitStatus = true;
    this.submitMessage = this.$t('invoice.loadding');

    const month = `${this.month.getMonth() + 1}/${this.month.getFullYear()}`;
    const response = await axios.get('/batch_create/invoices/new.json', {
      params: { invoices: { month } },
      paramsSerializer: function(params) {
        return jQuery.param(params);
      }
    });
    const { data, status } = response;
    if (status == 200) {
      this.updateDataHotSetting(data.build);
    }
  },
  methods: {
    updateDataHotSetting(data) {
      this.loaded = true;

      const result = this.formatDataHotSetting(data);
      this.$nextTick(() => {
        this.hotRef = this.$refs.invoices.hotInstance;
        this.hotSettings.data = result;

        this.hotRef.updateSettings(this.hotSettings);
      });
      this.submitStatus = true;
      this.submitMessage = this.$t('invoice.loaded');
    },
    formatDataHotSetting(data) {
      const dataInit = [];
      let index = 1;
      forEach(data, (rawData, key) => {
        if (!isEmpty(rawData)) {
          dataInit.push({
            check: false,
            key: key,
            deposited_money: rawData.contract.deposited_money || '',
            room_price: rawData.contract.room_price,
            day_used_per_month: rawData.invoice.day_used_per_month,
            ele_begin_number: rawData.electric.begin_number,
            ele_end_number: rawData.electric.end_number,
            ele_qty: `=IF(G${index} > F${index}, G${index} - F${index}, 0)`,
            ele_total: `=H${index} * ${rawData.electric.unit_price}`,
            wat_begin_number: rawData.water.begin_number,
            wat_end_number: rawData.water.end_number,
            wat_qty: `=IF(K${index} > J${index}, K${index} - J${index}, 0)`,
            wat_total: `=L${index} * ${rawData.water.unit_price}`,
            internet_unit: rawData.internet.unit_price,
            internet_total: `=E${index} * N${index} / ${this.totalDayInMonth}`,
            service_unit: rawData.service.unit_price,
            service_total: `=E${index} * P${index} / ${this.totalDayInMonth}`,
            parking_fee_unit: rawData.parking_fee.unit_price,
            parking_fee_qty: rawData.parking_fee.quantity,
            parking_fee_total: `=E${index} * R${index} * S${index} / ${this.totalDayInMonth}`,
            /* eslint-disable max-len */
            total: `=E${index} * D${index} / ${this.totalDayInMonth} + I${index} + M${index} + O${index} + Q${index} + T${index} - V${index} + W${index}`,
            reduce: rawData.invoice.reduce,
            added: rawData.invoice.added,
            holder_name: rawData.extend_data.holder_name,
            note: '',
            contract_id: rawData.contract.contract_id,
            room_id: rawData.contract.room_id
          });
        } else {
          dataInit.push({
            check: false,
            key: key,
            deposited_money: '',
            room_price: '',
            day_used_per_month: '',
            ele_begin_number: '',
            ele_end_number: '',
            ele_qty: '',
            ele_total: '',
            wat_begin_number: '',
            wat_end_number: '',
            wat_qty: '',
            wat_total: '',
            internet_unit: '',
            internet_total: '',
            service_unit: '',
            service_total: '',
            parking_fee_unit: '',
            parking_fee_qty: '',
            parking_fee_total: '',
            total: '',
            reduce: '',
            added: '',
            holder_name: '',
            note: '',
            contract_id: '',
            room_id: ''
          });
        }

        index++;
      });

      return dataInit;
    },
    checkData() {
      const data = this.hotRef.getData();
      if (!some(data, item => item[0])) {
        this.submitStatus = false;
        this.submitMessage = this.$t('invoice.choose_room');

        this.isValid = false;
        return false;
      }
      this.isValid = true;

      forEach(data, item => {
        if (item[0]) {
          if (item[5] > item[6] || item[9] > item[10] || item[4] > this.totalDayInMonth) {
            this.submitStatus = false;
            this.submitMessage = this.$t('invoice.error_msg', { room: item[1] });

            this.isValid = false;
            return false;
          }
        }
      });
      if (this.isValid) {
        this.submitStatus = true;
        this.submitMessage = this.$t('invoice.success_msg');
        return true;
      }
      return false;
    },
    submitData() {
      if (!this.checkData()) return;

      const data = this.hotRef.getData();

      let invoices = {};
      forEach( data, item => {
        if (item[0]) {
          invoices[item[1]] = {
            electric: { begin_number: item[5], end_number: item[6], quantity: item[7], total: item[8] },
            internet: { quantity: 1, total: item[13], unit_price: item[14] },
            invoice: { added: item[22], contract_id: item[25], day_used_per_month: item[4],
                       reduce: item[21], room_id: item[26], total: item[20] },
            parking_fee: { quantity: item[18], total: item[19], unit_price: item[17] },
            service: { quantity: 1, total: item[16], unit_price: item[15] },
            water: { begin_number: item[9], end_number: item[10], quantity: item[11], total: item[12] }
          };
        }
      });
      console.log(invoices);
    },
    onCloseFlash () {
      this.submitStatus = '';
      this.submitMessage = '';
    }
  }
};
</script>
