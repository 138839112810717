<template>
  <div class="card mb-5">
    <div
      class="card-header p-0"
      data-toggle="collapse"
      data-target="#collapseFormSearch"
    >
      <button
        class="btn dropdown-toggle"
        type="button"
      >
        <i class="fas fa-search" />
        <span class="font-weight-bold">{{ $t('user.search') }}</span>
      </button>
    </div>
    <div
      id="collapseFormSearch"
      class="collapse show"
    >
      <div class="card-body">
        <form
          id="search-user"
          @submit.prevent="submitForm"
        >
          <div class="form-group row">
            <div class="col-5">
              <div class="row">
                <label
                  class="col-3 col-form-label text-right"
                >{{ $t('user.name') }}</label>
                <div class="col-6">
                  <input
                    id="name"
                    v-model="name"
                    class="form-control"
                    type="text"
                    :placeholder="$t('user.placeholder_name')"
                  >
                </div>
              </div>
            </div>
            <div class="col-5">
              <div class="row">
                <label
                  class="col-3 col-form-label text-right"
                >{{ $t('user.phone') }}</label>
                <div class="col-6">
                  <input
                    id="phone"
                    v-model="phone"
                    class="form-control"
                    type="text"
                    inputmode="tel"
                    :placeholder="$t('user.placeholder_phone')"
                  >
                  <div
                    class="invalid-feedback"
                  >
                    {{ errorMsgPhone }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-5">
              <div class="row">
                <label
                  class="col-3 col-form-label text-right"
                >{{ $t('user.room_number') }}</label>
                <div class="col-6">
                  <input
                    id="room-number"
                    v-model="roomNumber"
                    class="form-control"
                    type="text"
                    inputmode="numeric"
                    :placeholder="$t('user.placeholder_room')"
                  >
                  <div
                    class="invalid-feedback"
                  >
                    {{ errorMsgRoomNumber }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-5">
              <InputRadioButtons
                v-model="status"
                input-id="status"
                :col-label="'col-3 text-right'"
                :col-input="'col-lg-9'"
                :label-text="$t('user.status')"
                :options="optionStatus"
                :value-input="status"
              />
            </div>
          </div>
          <div class="form-group row flex-row-reverse">
            <div class="col-2">
              <button
                class="btn btn-secondary text-nowrap"
              >
                {{ $t('user.search') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import InputRadioButtons from "../components/inputRadioButtons";
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('user');
import { isEmpty } from 'lodash';

export default {
  components: {
    InputRadioButtons
  },
  data() {
    return {
      roomNumber: '',
      name: '',
      phone: '',
      errorMsgRoomNumber: '',
      errorMsgPhone: '',
      optionStatus: {
        active: this.$t('user.active'),
        inactive: this.$t('user.inactive'),
        all: this.$t('user.all')
      },
      status: 'all'
    };
  },
  computed: {
    ...mapState(['params'])
  },
  mounted() {
    if (!isEmpty(this.params)) {
      this.roomNumber = this.params.room_number;
      this.name = this.params.name;
      this.phone = this.params.phone;
      this.status = this.params.status || 'active';
    }
  },
  methods: {
    ...mapActions(['submitFormSearch']),
    submitForm() {
      if (!this.validateRoomNumber() || !this.validatePhone()) return;

      const page = 1;
      const params = {
        name: this.name,
        phone: this.phone,
        room_number: this.roomNumber,
        status: this.status
      };
      this.submitFormSearch({ params, page });
    },
    validateRoomNumber() {
      const regexRoomNumber = /^(\d{0,8}|(\d{0,8}\-\d{0,8}))([,\s\t\r\n\f](\d{0,8}|(\d{0,8}\-\d{0,8})))*$/;
      this.errorMsgRoomNumber = '';
      if (regexRoomNumber.test(this.roomNumber)) return true;

      this.errorMsgRoomNumber = this.$t('user.error_room_number');
      return false;
    },
    validatePhone() {
      const regexPhone = /[\d{1,11}. ]*$/;
      this.errorMsgPhone = '';
      if (regexPhone.test(this.phone)) return true;

      this.errorMsgPhone = this.$t('user.error_phone');
      return false;
    }
  }
};
</script>

<style lang = "scss" scoped>
  .invalid-feedback {
    font-size: 90%;
    display: block;
  }
</style>
