<template>
  <tr class="text-center">
    <td class="font-weight-bold">
      {{ contractHistory.id }}
    </td>
    <td>{{ contractHistory.date_changed | toString }}</td>
    <td>{{ contractHistory.room_number }}</td>
    <td
      role="button"
      @click="detailContract(contractHistory.contract_id)"
    >
      {{ $t(`contract_history.code.${contractHistory.code}`) }}
    </td>
    <td
      role="button"
      @click="detailUser(contractHistory.holder_id)"
    >
      {{ contractHistory.holder_name }}
    </td>
    <td>{{ contractHistory.admin_name }}</td>
  </tr>
</template>

<script>
export default {
  filters: {
    toString: function(value) {
      if (!value) return '';

      return new Date(value).toLocaleString();
    }
  },
  props: {
    contractHistory: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    detailContract: function(val) {
      const self = this;
      this.$store.dispatch('contract/getDetailContract', { id: val }).then(() => {
        self.$emit('click_contract');
      });
    },
    detailUser: function(val) {
      const self = this;
      this.$store.dispatch('user/loadDetailUser', { id: val }).then(() => {
        self.$emit('click_user');
      });
    }
  }
};
</script>
