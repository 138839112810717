<template>
  <div>
    <ol class="list-group list-group-numbered list-group-horizontal">
      <li class="list-group-item list-group-item-secondary d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">
            {{ $t('floor.total_rooms') }}
          </div>
          {{ $t('floor.total_rooms_description') }}
        </div>
        <h3><span class="badge bg-light rounded-pill">{{ totalRooms }}</span></h3>
      </li>
      <li class="list-group-item list-group-item-success d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">
            {{ $t('floor.occupied_rooms') }}
          </div>
          {{ $t('floor.occupied_rooms_description') }}
        </div>
        <h3><span class="badge bg-light rounded-pill">{{ roomsOccupied }}</span></h3>
      </li>
      <li class="list-group-item list-group-item-warning d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">
            {{ $t('floor.no_show_rooms') }}
          </div>
          {{ $t('floor.no_show_rooms_description') }}
        </div>
        <h3><span class="badge bg-light rounded-pill">{{ roomsNoShow }}</span></h3>
      </li>
      <li class="list-group-item list-group-item-danger d-flex justify-content-between align-items-start">
        <div class="ms-2 me-auto">
          <div class="fw-bold">
            {{ $t('floor.vacant_and_ready_rooms') }}
          </div>
          {{ $t('floor.vacant_and_ready_rooms_description') }}
        </div>
        <h3><span class="badge bg-light rounded-pill">{{ roomsVacant }}</span></h3>
      </li>
    </ol>
  </div>
</template>

<script>
  export default {
    props: {
      floors: {
        type: Array,
        required: true,
        default: function() { []; }
      }
    },
    computed: {
      roomsOccupied: function() {
        return this.totalRoomsByStatus('hired');
      },
      totalRooms: function() {
        return this.floors.reduce((sum, floor) => sum + floor.rooms.length, 0);
      },
      roomsVacant: function() {
        return this.totalRoomsByStatus('empty');
      },
      roomsNoShow: function() {
        return this.totalRoomsByStatus('deposited');
      } 
    },
    methods: {
      totalRoomsByStatus(status) {
        if (!this.floors) return;

        return this.floors.reduce((sum, floor) => sum + floor.rooms.filter(room => room.status == status).length, 0);
      }
    }
  };
</script>
