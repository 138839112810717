<template>
  <div class="card mb-5">
    <div
      class="card-header p-0"
      data-toggle="collapse"
      data-target="#collapseFormSearch"
    >
      <button
        class="btn dropdown-toggle"
        type="button"
      >
        <i class="fas fa-search" />
        <span class="font-weight-bold">{{ $t('contract.search') }}</span>
      </button>
    </div>
    <div
      id="collapseFormSearch"
      class="collapse show"
    >
      <div class="card-body">
        <form
          id="search-contracts"
          @submit.prevent="submitForm"
        >
          <div class="form-group row">
            <label
              for="roomNumber"
              class="col-2 col-form-label text-right"
            >{{ $t('contract.room_number') }}</label>
            <div class="col-3">
              <input
                id="roomNumber"
                v-model="roomNumber"
                class="form-control"
                type="text"
                inputmode="numeric"
                :placeholder="$t('contract.placeholder_room_number_search')"
              >
            </div>
            <label
              for="holder_name"
              class="col-2 col-form-label text-right"
            >{{ $t('contract.holder_name') }}</label>
            <div class="col-3">
              <input
                id="holder_name"
                v-model="holderName"
                class="form-control"
                type="text"
                :placeholder="$t('contract.placeholder_holder_name_search')"
              >
            </div>
            <div class="col-1">
              <button
                type="submit"
                class="btn btn-light text-nowrap"
              >
                {{ $t('contract.search') }}
              </button>
            </div>
          </div>
          <InputRadioButtons
            v-model="status"
            input-id="status"
            col-label="col-2 text-right"
            :label-text="$t('contract.status')"
            :options="optionStatus"
            :value-input="status"
          />
          <div class="row m-0 justify-content-end">
            <button
              class="btn btn-secondary text-nowrap"
              @click="resetForm"
            >
              {{ $t('contract.reset') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import InputRadioButtons from '../components/inputRadioButtons';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('contract');

export default {
  components: {
    InputRadioButtons
  },
  data() {
    return {
      roomNumber: '',
      holderName: '',
      status: '',
      optionStatus: {
        active: this.$t('contract.active'),
        inactive: this.$t('contract.inactive'),
        all: this.$t('contract.all')
      }
    };
  },
  computed: {
    ...mapState(['params'])
  },
  mounted() {
    this.roomNumber = this.params.room_number;
    this.holderName = this.params.holder_name;
    this.status = this.params.status || 'active';
  },
  methods: {
    ...mapActions(['submitFormSearch']),
    submitForm() {
      const page = 1;
      const params = {
        room_number: this.roomNumber,
        holder_name: this.holderName,
        status: this.status
      };
      this.submitFormSearch({ params, page });
    },
    resetForm() {
      this.roomNumber = '';
      this.holderName = '';
      this.monthExport = '';
      this.yearExport = '';

      this.submitForm();
    }
  }
};
</script>
