<template>
  <div id="app">
    <FlashMessage :position="'right top'" />
    <BuildingRoomsStatus
      :floors="floors"
    />
    <TableFloor
      :floors="floors"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BuildingRoomsStatus from './component_floors/buildingRoomsStatus.vue';
import TableFloor from './component_floors/tableFloors.vue';

export default {
  components: {
    TableFloor,
    BuildingRoomsStatus
  },
  computed: {
    ...mapState({
      floors: (state) => state.floor.floors
    })
  },
  created: function() {
    this.$store.dispatch('floor/getFloors');
  }
};
</script>
