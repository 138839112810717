<template>
  <div class="card mb-5">
    <div
      class="card-header p-0"
      data-toggle="collapse"
      data-target="#collapseFormSearch"
    >
      <button
        class="btn dropdown-toggle"
        type="button"
      >
        <i class="fas fa-search" />
        <span class="font-weight-bold">{{ $t('invoice.search') }}</span>
      </button>
    </div>
    <div
      id="collapseFormSearch"
      class="collapse show"
    >
      <div class="card-body">
        <form
          id="search-invoices"
          @submit.prevent="submitForm"
        >
          <div class="form-group row">
            <label
              for="roomNumber"
              class="col-2 col-form-label text-right"
            >{{ $t('invoice.room_number') }}</label>
            <div class="col-3">
              <input
                id="roomNumber"
                v-model="roomNumber"
                class="form-control"
                type="text"
                inputmode="search"
                :placeholder="$t('invoice.placeholder_search')"
              >
            </div>
            <div class="col-1">
              <button
                type="submit"
                class="btn btn-light text-nowrap"
              >
                {{ $t('invoice.search') }}
              </button>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2 col-form-label text-right">
              {{ $t('invoice.filter') }}
              |
              {{ $t('invoice.month') }}
            </label>
            <div class="col-8">
              <div
                class="btn-group flex-wrap"
                role="group"
              >
                <button
                  v-for="(month, index) in resultMonths"
                  :key="index"
                  :ref="`button_month_${month}`"
                  type="button"
                  :class="['btn btn-light', monthExport == month ? 'active' : '']"
                  @click="setMonth(month)"
                >
                  {{ $t('invoice.month_names')[month] }}
                </button>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2 col-form-label text-right">
              {{ $t('invoice.year') }}
            </label>
            <div class="col-8">
              <div
                class="btn-group flex-wrap"
                role="group"
              >
                <button
                  v-for="(year, index) in resultYears"
                  :key="index"
                  :ref="`button_year_${year}`"
                  type="button"
                  :class="['btn btn-light', yearExport == year ? 'active' : '']"
                  @click="setYear(year)"
                >
                  {{ year }}
                </button>
              </div>
            </div>
          </div>
          <div class="row m-0 justify-content-end">
            <button
              class="btn btn-secondary text-nowrap"
              @click="resetForm"
            >
              {{ $t('invoice.reset') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('invoice');

export default {
  data() {
    return {
      roomNumber: '',
      monthExport: '',
      yearExport: ''
    };
  },
  computed: {
    ...mapState(['params', 'resultMonths', 'resultYears', 'perPage'])
  },
  mounted() {
    this.roomNumber = this.params.room_number;
    this.monthExport = this.params.month_export;
    this.yearExport = this.params.year_export;
  },
  methods: {
    ...mapActions(['submitFormSearch']),
    setMonth(month) {
      if (month == this.monthExport) {
        this.monthExport = '';
        const button = this.$refs[`button_month_${month}`][0];
        button.blur();
      } else {
        this.monthExport = month;
      }

      this.submitForm();
    },
    setYear(year) {
      if (year == this.yearExport) {
        this.yearExport = '';
        const button = this.$refs[`button_year_${year}`][0];
        button.blur();
      } else {
        this.yearExport = year;
      }

      this.submitForm();
    },
    submitForm() {
      const params = {
        room_number: this.roomNumber,
        month_export: this.monthExport,
        year_export: this.yearExport
      };
      const payload = {
        params: params,
        page: 1,
        perPage: this.perPage
      };
      this.submitFormSearch(payload);
    },
    resetForm() {
      this.roomNumber = '';
      this.monthExport = '';
      this.yearExport = '';

      this.submitForm();
    }
  }
};
</script>
