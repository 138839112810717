const messages = {
  en: {
    user: {
      no: 'No.',
      new_user: 'New user',
      add_user: 'Add user',
      delete: 'Delete',
      first_name: 'First name',
      last_name: 'Last name',
      email: 'Email',
      phone: 'Phone',
      birthday: 'Birthday',
      identity_card: 'Identity card',
      room_number: 'Room number',
      select_room: 'Select room',
      close: 'Close',
      submit: 'Submit',
      restore: 'Restore',
      back: 'Back',
      save_change: 'Save changes',
      edit_user: 'Edit user',
      delete_user: 'Delete user',
      user_id: 'User id',
      created_at: 'Created at',
      edit_or_delete: 'Edit / Delete',
      active: 'Active',
      self: 'User',
      deleted: 'deleted!',
      info: 'Info',
      errors: 'Errors',
      import_user: 'Import user',
      not_errors: 'Not errors',
      confirmation: {
        perform_delete_user: 'Do you want delete user',
        yes: 'Yes',
        close: 'Close'
      },
      avatar: 'Avatar',
      search: 'Search',
      name: 'Name',
      placeholder_name: 'Eg: A hoặc Nguyễn Văn',
      placeholder_phone: 'Eg: 0123456789',
      placeholder_room: 'Eg: 100, 101, 102-105',
      error_room_number: 'Please enter the number',
      error_phone: 'Please enter a number between 1-12 characters',
      show_user: 'Show User',
      edit_user: 'Edit User',
      inactive: 'Inactive',
      all: 'All',
      reset: 'Reset',
      status: 'Status'
    },
    floor: {
      self: 'Floor',
      edit_room: 'Edit Room',
      room_number: 'Room number',
      room_area: 'Room area',
      room_holder: 'Room holder',
      select_holder: 'Select holder',
      room_status: 'Room status',
      close: 'Close',
      submit: 'Submit',
      back: 'Back',
      save_change: 'Save changes',
      hired: 'Hired',
      empty: 'Empty',
      deposited: 'Deposited',
      total_rooms: 'Total rooms',
      total_rooms_description: 'Number of rooms in this building',
      occupied_rooms: 'OCC - Occupied',
      occupied_rooms_description: 'A guest is currently occupied in the room',
      no_show_rooms: 'NS - No Show',
      no_show_rooms_description: 'A guest who made a room reservation but did not register or Check-in',
      vacant_and_ready_rooms: 'VR - Vacant and Ready',
      vacant_and_ready_rooms_description: 'Room is Vacant and Ready for Check-in'
    },
    contract: {
      contract_id: 'Contract id',
      holder_name: 'Holder name',
      room_number: 'Room number',
      room_price: 'Room price',
      deposited_money: 'Deposited money',
      start_date: 'Start date',
      end_date: 'End date',
      duplicate_and_edit: 'Duplicate and Edit',
      extend: 'Extend',
      details: 'Details',
      terminate: 'Terminate',
      detail_contract: 'Detail contract',
      id: 'ID',
      month_rented: 'Months rented',
      month_remain: 'Months remaining',
      total_money: 'Total moneys',
      close: 'Close',
      new_contract: 'New contract',
      select_holder: 'Select holder',
      select_room: 'Select room',
      deposited: 'Deposited',
      submit: 'Submit',
      number_of_month: 'Number of months',
      or_input: 'or input',
      back: 'Back',
      extend_contract: 'Extend contract',
      confirm: 'Confirm',
      terminate_contract: 'Terminate contract',
      confirmation: {
        perform_terminate_contract: 'Do you want terminate contract',
        yes: 'Yes',
        close: 'Close'
      },
      defaultMonth: {
        month: '{count} months'
      },
      search: 'Search',
      room_number: 'Room number',
      placeholder_room_number_search: 'Eg: 100, 101, 102-105',
      placeholder_holder_name_search: 'Eg: John Doe',
      status: 'Status',
      active: 'Active',
      inactive: 'Inactive',
      all: 'All',
      reset: 'Reset'
    },
    invoice: {
      room: 'Room',
      deposited: 'Deposited',
      price: 'Room price',
      electric: 'Electric',
      water: 'Water',
      internet: 'Internet',
      num: 'Num',
      service: 'Service',
      total: 'Total',
      reduce: 'Reduce',
      holder: 'Holder',
      begin_number: 'Begin',
      end_number: 'End',
      use: 'Use',
      into_money: 'Info money',
      note: 'Note',
      not_contract: 'Not contract',
      check_form: 'Check data',
      submit_form: 'Submit',
      error_msg: 'Please check data for room {room} again',
      success_msg: 'Data is valid',
      choose_room: 'Please choose rooms',
      batch_create: 'Batch create invoices',
      invoice_id: 'Invoice ID',
      month: 'Month',
      year: 'Year',
      total_income: 'Total income',
      export_print: 'Export/Print',
      no: 'No',
      print_title: 'ROOM RENTAL NOTICE',
      room_no: 'Room No.',
      service: 'Service',
      unit: 'Unit',
      qty: 'Qty',
      unit_price: 'Unit price',
      room_price: 'Room price',
      ele_price: 'Electricity bill',
      wat_price: 'Water bill',
      paf_price: 'Parking fee',
      int_price: 'Internet money',
      ser_price: 'Service charge',
      total_1: 'Total (1)',
      reduce_or_increase: 'Reduced or increased expenses (if any)',
      reduce_1: 'Reduce excess money',
      total_2: 'Total (2)(2-1)',
      print_total: 'Total (1+2)',
      num_month: '1',
      from_to: '(From {begin} to {end})',
      payment_deadline: '- Room rental payment deadline: from 01-03 of every month (quarterly)',
      transfer_infor: '- Transfer information:',
      account_name: '+ Account name: Trương Văn Cường',
      account_number: '+ Account number: 1903 6705 0380 10. Techcombank - Branch Ba Đình',
      transfer_content: '+ Transfer content:',
      content: 'Room no. .......... Holder .......... Pay the monthly rent ..........',
      date_time: 'Ha Noi, day .......... month .......... year ..........',
      payer: 'Payer',
      receiptor: 'Receiptor',
      print: 'Invoice printing',
      export_image: 'Export Image',
      copy_success: 'Copied to clipboard',
      generate_success: 'Generate image success',
      add_invoice: 'Add invoice',
      new_invoice: 'New invoice',
      room_number: 'Room number',
      room_price: 'Room price',
      day_used_per_month: 'Day used per month',
      electric_start: 'Electric start',
      electric_end: 'Electric end',
      water_start: 'Water start',
      water_end: 'Water end',
      unit_price_internet: 'Internet price',
      unit_price_parking_fee: 'Parking fee',
      quantity_parking: 'Motorbike number',
      unit_price_service_fee: 'Service fee',
      added: 'Added',
      reduce: 'Reduce',
      added: 'Added',
      total: 'Total',
      select_room: 'Select room',
      details: 'Details',
      close: 'Close',
      detail_invoice: 'Detail invoice',
      input_month: 'Please enter month',
      print_x: 'Print',
      download: 'Download',
      edit_or_delete: 'Edit / Delete',
      edit_invoice: 'Edit invoice',
      submit: 'Submit',
      back: 'Back',
      save_change: 'Save changes',
      self: 'Invoice',
      info: 'Info',
      delete_invoice: 'Delete invoice',
      deleted: 'Deleted',
      confirmation: {
        perform_delete_invoice: 'Do you want delete invoice',
        yes: 'Yes',
        close: 'Close'
      },
      search: 'Search',
      placeholder_search: 'Enter the name of the room, the keyword to search',
      filter: 'Filter',
      month_names: {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec'
      },
      reset: 'Reset',
      loadding: 'Loadding ...',
      loaded: 'Load done',
      list_invoices: 'List Invoices',
      note: 'Note',
      copy_image: 'Copy Image'
    },
    revenues: {
      time: 'Date time',
      room: 'Room',
      report_type: 'Type report',
      pla_room_number: 'Choose room',
      start_date: 'Start date',
      pla_start_date: 'Choose start date',
      end_date: 'End date',
      pla_end_date: 'Choose end date',
      filter: 'Filter',
      month: 'Month',
      room_price: 'Room price',
      electric: 'Electric',
      water: 'Water',
      internet: 'Internet',
      price_parking_fee: 'Parking fee',
      service: 'Service',
      reduce: 'Reduce',
      total_income: 'Total',
      search: 'Search',
      blank: "Can't be blank",
      added: 'Added'
    },
    contract_history: {
      id: 'ID',
      time: 'Time',
      room: 'Room',
      type_of_transaction: 'Type of transaction',
      host: 'Man in charge',
      holder_name: 'Host room',
      code: {
        add: 'Sign new contract',
        renew: 'Extend',
        terminate: 'Terminate contract'
      }
    },
    upload_file: {
      import_file: 'Import file',
      close: 'Close',
      upload: 'Upload'
    },
    flash: {
      success: 'Success!',
      unsuccess: 'Unsuccess!'
    },
    paginate: {
      result: 'Total: '
    },
    input_image_file: {
      remove: 'Remove',
      select_file: 'Select file'
    },
    export: {
      csv: 'Export CSV'
    },
    per_page: {
      show: 'Show',
      items: 'items'
    },
    admin: {
      full_name: 'Full name',
      profile: 'Account information',
      phone: 'Phone',
      birthday: 'Birthday',
      password: 'Password',
      password_confirmation: 'Password confirmation',
      email: 'Email',
      save: 'Save'
    },
    organization: {
      organization_id: 'Organization ID',
      name: 'Organization Name',
      description: 'Description'
    }
  }
};

export default messages;
